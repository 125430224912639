.calls-query-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  background-color: white;
}

.calls-query-button:active{
    border-color: #41badb;
}

.icon-wrapper {
    margin-right: 8px; /* Adjust the spacing between the icon and text */
  }

.button-text{
    color: #61dafb;
    font-weight:bold;
    font-size: 16px;
}

.query-panel{
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-left: 500px;
}

.query-timespan-combo{
    padding: 10px;
}

.timespan-combo-label{
    padding-right: 20px;
}